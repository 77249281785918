<template>
    <div class="Printer full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex ">
                        <v-col cols="6" class="pa-0 pr-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.printer.printer') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-select :items="selectPrinterType" item-text="name" item-value="value"  v-model="printer.type" class="printer-type-dropdown" outlined hide-details="auto" attach :readonly="!checkUserRights('systemPrinterEdit')" :class="{'noAction': !checkUserRights('systemPrinterEdit')}" />
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4" :class="{'disabled': printer.type != 'Network'}">
                                    <p>{{ $t('system.printer.address') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input inputName="printer-address" @onInputFocus="onInputFocus" :disabled="printer.type != 'Network'" onlyURL :readonly="!checkUserRights('systemPrinterEdit')" :class="{'noAction': !checkUserRights('systemPrinterEdit')}" :rules="[rules.networkPrinter, rules.maxLength48, rules.alphaNumericPointValue]"/>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.printer.printerDriver') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-select :items="selectDriver" v-model="printer.printerDriver" class="printer-printerDriver-dropdown" outlined hide-details="auto" attach  :readonly="!checkUserRights('systemPrinterEdit')" :class="{'noAction': !checkUserRights('systemPrinterEdit')}" :rules="[rules.networkPrinter]"/>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.printer.paper') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-select :items="selectPaper" v-model="printer.paper" class="printer-paper-dropdown" outlined hide-details="auto" attach :readonly="!checkUserRights('systemPrinterEdit')" :class="{'noAction': !checkUserRights('systemPrinterEdit')}" :rules="[rules.networkPrinter]"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pl-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <switch-on-off name="printer-printProductTest" :disabled="!checkUserRights('systemPrinterEdit')" :class="{'active': printProductTest}" class="mr-4"/>
                                </v-col>
                                <v-col class="col-8">
                                    <p>{{ $t('system.printer.printProductTest') }}</p>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <switch-on-off name="printer-printQuickTest" :disabled="!checkUserRights('systemPrinterEdit')" :class="{'active': printQuickTest}" class="mr-4"/>
                                </v-col>
                                <v-col class="col-8">
                                    <p>{{ $t('system.printer.printQuickTest') }}</p>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <switch-on-off name="printer-printCalAdj" :disabled="!checkUserRights('systemPrinterEdit')" :class="{'active': printCalAdj}" class="mr-4"/>
                                </v-col>
                                <v-col class="col-8">
                                    <p>{{ $t('system.printer.printCalAdj') }}</p>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <switch-on-off name="printer-printGraph" :disabled="!checkUserRights('systemPrinterEdit')" :class="{'active': printGraph}" class="mr-4"/>
                                </v-col>
                                <v-col class="col-8">
                                    <p>{{ $t('system.printer.printGraph') }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToSystem"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('systemPrinterEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'

import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Printer',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            original: {},
            selectPrinterType: [
                {
                    'name': this.$t('system.printer.usbPrinter'),
                    'value': 'USB'
                },{
                    'name': this.$t('system.printer.networkPrinter'),
                    'value': 'Network'
                }
            ],
            selectPaper: [
                'A4',
                'Letter',
            ],
            selectDriver: [
                'PCL',
                'PS',
            ],
            rules: {
                maxLength48: v => {
                    if (v){
                        return v.length < 48 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                networkPrinter: v => {
                    if (this.printer.type != 'Network'){
                        return true;
                    } else {
                        return (this.printer.type == 'Network' && v != '') || `${this.$t('validation.required')}`;
                    }
                },
                alphaNumericPointValue: v => {
                    if (v){
                        let numericPoint = /^[A-Za-z0-9.:/]+$/;
                        return numericPoint.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'printer'
        ]),
        printProductTest(){
            return this.$store.state.printer.printProductTest;
        },
        printQuickTest(){
            return this.$store.state.printer.printQuickTest;
        },
        printCalAdj(){
            return this.$store.state.printer.printCalAdj;
        },
        printGraph(){
            return this.$store.state.printer.printGraph;
        },
    },
    methods:{
        goToSystem(){
            this.$router.push('system');
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()){
                this.showSaveProgress = true;
                let Printer = {}
                if (this.printer.type != this.original.type){
                    Printer.type = this.printer.type;
                }
                if (this.printer.address != this.original.address && this.printer.address != ""){
                    Printer.address = this.printer.address;
                }
                if (this.printer.hostname != this.original.hostname && this.printer.hostname != ""){
                    Printer.hostname = this.printer.hostname;
                }
                if (this.printer.gateway != this.original.gateway && this.printer.gateway != ""){
                    Printer.gateway = this.printer.gateway;
                }
                if (this.printer.subnet != this.original.subnet && this.printer.subnet != ""){
                    Printer.subnet = this.printer.subnet;
                }
                if (this.printer.printerDriver != this.original.printerDriver){
                    Printer.printerDriver = this.printer.printerDriver;
                }
                if (this.printer.paper != this.original.paper){
                    Printer.paper = this.printer.paper;
                }
                if (this.printer.printProductTest != this.original.printProductTest){
                    Printer.printProductTest = this.printer.printProductTest;
                }
                if (this.printer.printQuickTest != this.original.printQuickTest){
                    Printer.printQuickTest = this.printer.printQuickTest;
                }
                if (this.printer.printCalAdj != this.original.printCalAdj){
                    Printer.printCalAdj = this.printer.printCalAdj;
                }
                if (this.printer.printGraph != this.original.printGraph){
                    Printer.printGraph = this.printer.printGraph;
                }
                if (Object.keys(Printer).length === 0 && Printer.constructor === Object){
                    this.showSaveProgress = false;
                    this.showSaveSuccess = true;
                    this.watcher();
                    setTimeout(()=>{
                        this.saveDisabled = true;
                        this.showSaveSuccess = false;
                    }, 800)
                } else {
                    this.$store.dispatch('postAxiosNoSetter', ['admin/printer/settings', Printer])
                        .then(response=>{
                            this.showSaveProgress = false;
                            if (response.status === 200){
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(()=>{
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                }
            }
        },
        watcher(){
            //copy printer to original for comparison
            this.original = JSON.parse(JSON.stringify(this.printer));

            let type = this.$watch('$store.state.printer.type', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let address = this.$watch('$store.state.printer.address', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let hostname = this.$watch('$store.state.printer.hostname', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let gateway = this.$watch('$store.state.printer.gateway', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let subnet = this.$watch('$store.state.printer.subnet', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let printerDriver = this.$watch('$store.state.printer.printerDriver', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let paper = this.$watch('$store.state.printer.paper', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let printProductTest = this.$watch('$store.state.printer.printProductTest', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let printQuickTest = this.$watch('$store.state.printer.printQuickTest', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let printCalAdj = this.$watch('$store.state.printer.printCalAdj', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let printGraph = this.$watch('$store.state.printer.printGraph', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function(){
                type();
                address();
                hostname();
                gateway();
                subnet();
                printerDriver();
                paper();
                printProductTest();
                printQuickTest();
                printCalAdj();
                printGraph();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input) {
            this.$emit('onInputFocus', [input.target.id])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/printer/settings', 'printer'])
            .then(response=>{
                if (response.status === 200){
                    this.watcher();
                }
            });
    },
    beforeRouteLeave (to, from, next) {
        if(this.saveDisabled){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>